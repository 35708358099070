<div id="dialog-tarifas-realestate" class="dialog-container dialog-scroll container-fluid">
  <div class="header-dialog">
    <div class="text-end mb-2">
      <span class="material-symbols-outlined" [mat-dialog-close]="true" role="button">close</span>
    </div>
    <h3 class="me-4">Conoce las tarifas<sup>*</sup> aplicables a los proyectos ofrecidos por SEGO Real Estate</h3>
  </div>

  <div class="linea-separadora-1 border-primary-100 my-3"></div>

  <p class="p1-regular mb-3">Conoce las <span class="p1-semibold">tarifas</span> que te ofrecemos desde el Grupo
  SEGOFINANCE para
  <span class="p1-semibold">financiar tus proyectos y darlos a conocer</span> en nuestra red de inversores.
</p>

<div class="tarifas-tabla bg-secondary-estate-200 text-center mb-3 p-4">
  <div class="row d-flex align-items-center">
    <div class="col-md-5">
      <p class="tabs primary-500">Fijo</p>
      <p class="p1-cifras primary-900 mb-0">2.500 €</p>
      <p class="p3-semibold primary-800">IVA no incluido</p>
      <p class="p1-medium neutral-900">comisión a éxito</p>
    </div>
    <div class="col-md-2">
      <p class="p1-cifras primary-900">+</p>
    </div>
    <div class="col-md-5">
      <p class="tabs primary-500">Variable</p>
      <p class="p1-cifras primary-900 mb-0">hasta 8%</p>
      <p class="p3-semibold primary-800">IVA no incluido</p>
      <p class="p1-medium neutral-900">comisión a éxito</p>
    </div>
  </div>
</div>

<p class="p1-semibold mb-2">Como promotor podrás beneficiarte de los siguientes servicios:</p>

<ul class="list-unstyled p2-regular ms-2">
  <li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Análisis y validación del proyecto.</li>
<li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Publicación, promoción y formalización del proyecto en nuestra plataforma.</li>
<li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Promoción comercial y publicidad de la empresa a nivel nacional.</li>
<li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Sello de confianza del Grupo SegoFinance.</li>
</ul>

<div class="d-none d-md-block my-4 text-center">
  @if (data.plataformaId === 'REALESTATE') {
    <a class="btn btn-primary mx-auto" title="Financia tu proyecto"
    routerLink="/buscas-financiacion" [mat-dialog-close]="true">Financia tu proyecto</a>
  }
  @if (data.plataformaId !== 'REALESTATE') {
    <a class="btn btn-primary mx-auto" title="Financia tu proyecto"
    href="{{data.domainUrl}}/buscas-financiacion" [mat-dialog-close]="true">Financia tu proyecto</a>
  }
</div>

<p class="mt-4 mt-md-0 mb-mobile mb-lg-2 neutral-800 p3-regular">(*) SEGOFINANCE no cobra ninguna tarifa a los
  inversores
  por la prestación
de los servicios.</p>

<div class="d-block d-md-none fixed-bottom bg-white">

  <div class="linea-separadora-1 border-primary-100 my-4"></div>

  <div class="row">
    <div class="col-10 mx-auto text-center mb-4">
      @if (data.plataformaId === 'REALESTATE') {
        <a class="btn btn-primary w-100" title="Financia tu proyecto"
        routerLink="/buscas-financiacion" [mat-dialog-close]="true">Financia tu proyecto</a>
      }
      @if (data.plataformaId !== 'REALESTATE') {
        <a class="btn btn-primary w-100" title="Financia tu proyecto"
          href="{{data.domainUrl}}/buscas-financiacion" [mat-dialog-close]="true">Financia
        tu proyecto</a>
      }
    </div>
  </div>
</div>
</div>